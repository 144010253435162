<template>
  <BulkImport
    :display="showBulkImport"
    :type="'city'"
    @closeImport="closeBulkImport"
    @importData="importData"
  ></BulkImport>
  <AddEditCity
    :display="showAddEditCity"
    :cityIndex="editCityIndex"
    @closeAddCity="closeAddCity"
  >
  </AddEditCity>

  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6 md:col-2">
          <h4>{{ $t("settings.cities") }}</h4>
        </div>
        <div class="text-center text-right col-6 md:col-2">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkDownload')"
            @click="bulkExport"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkAddition')"
            @click="bulkCity"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.addCity')"
            @click="addCity"
            class="mr-3"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button :label="$t('global.saveJson')" @click="exportJson"></Button>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        :value="citiesValues"
        @rowReorder="onRowReorder"
        responsiveLayout="scroll"
        showGridlines
        :loading="loading"
        ref="citiesTable"
        @page="changePage"
      >
        <Column
          :rowReorder="true"
          :reorderableColumn="false"
          headerStyle="width: 3rem"
          class="text-center"
        />
        <Column field="id" :header="$t('settings.code')" class="text-center">
          <template #body="{ data }">
            <div>
              {{ data.id }}
            </div>
          </template>
        </Column>
        <Column
          field="nameGreek"
          :header="$t('settings.nameGreek')"
          class="text-center"
        >
          <template #body="{ data }">
            <div>
              {{ data.nameGreek }}
            </div>
          </template>
        </Column>
        <Column
          field="nameEnglish"
          :header="$t('settings.nameEnglish')"
          class="text-center"
        >
          <template #body="{ data }">
            <div>
              {{ data.nameEnglish }}
            </div>
          </template>
        </Column>
        <Column
          field="latitude"
          :header="$t('settings.latitude')"
          class="text-center"
        >
          <template #body="{ data }">
            <div>
              {{ data.latitude }}
            </div>
          </template>
        </Column>
        <Column
          field="longitude"
          :header="$t('settings.longitude')"
          class="text-center"
        >
          <template #body="{ data }">
            <div>
              {{ data.longitude }}
            </div>
          </template>
        </Column>
        <Column :header="$t('global.actions')">
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly align-items-center">
              <Button
                class="p-button-text mdi mdi-24px mdi-pencil"
                @click="editCity(index)"
              />
              <Button
                icon="mdi mdi-close-circle mdi-24px"
                class="p-button-rounded p-button-text p-button-text"
                @click="deleteCity(data, index)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <!-- <Column header="test">
          <template #body>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30" data-v-4da3ac46="">
          </template>
        </Column> -->
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import BulkImport from "../BulkImport.vue";
import AddEditCity from "./addEditCity.vue";
export default {
  name: "Cities",
  components: {
    BulkImport,
    AddEditCity,
  },
  data() {
    return {
      citiesValues: [],
      editIndex: null,
      showBulkImport: false,
      loading: false,
      showAddEditCity: false,
      editCityIndex: null,
      currentPage: 0,
      currentRows: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("cities");
    this.loading = false;
  },
  computed: {
    ...mapGetters(["cities", "isMobile"]),
  },
  watch: {
    cities() {
      this.citiesValues = [...this.cities];
    },
  },
  methods: {
    changePage(data) {
      this.currentPage = data.page;
      this.currentRows = data.rows;
    },
    bulkCity() {
      this.showBulkImport = true;
    },
    closeBulkImport() {
      this.showBulkImport = false;
    },
    onRowReorder(event) {
      console.log(event);
      this.citiesValues = [...event.value];
    },
    async importData() {
      //       await this.$store.dispatch("updateCities", data);
    },
    async exportJson() {
      this.$store.dispatch("exportJson", {
        name: "cities",
        json: this.citiesValues,
      });
    },
    addCity() {
      this.showAddEditCity = true;
    },
    closeAddCity(newCity) {
      this.showAddEditCity = false;
      this.editCityIndex = null;

        console.log(newCity);
      if (newCity) {
        this.citiesValues.push(newCity);
        this.$store.commit("cities", this.citiesValues);
      } else {
        this.citiesValues = this.cities;
      }
    },
    editCity(index) {
      this.showAddEditCity = true;
      this.editCityIndex = index + this.currentPage * this.currentRows;
    },
    deleteCity(city, cityIndex) {
      this.$confirm.require({
        message: this.$t("settings.deleteCity", [city.nameGreek]),
        header: this.$t("settings.deleteConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: this.$t("global.yes"),
        rejectLabel: this.$t("global.no"),
        accept: () => {
          this.citiesValues.splice(
            cityIndex + this.currentPage * this.currentRows,
            1
          );
          this.$store.commit("cities", this.citiesValues);
        },
      });
    },
    exportCSV() {
      this.$refs.citiesTable.exportCSV();
    },
    async bulkExport() {
      await this.$store.dispatch("bulkExport", "cities");
    },
  },
};
</script>
