<template>
  <Dialog
    :header="
      cityIndex == null ? $t('settings.createCity') : $t('settings.editCity')
    "
    v-model:visible="viewDialog"
    :draggable="false"
    :modal="true"
    :style="{ width: '40vw' }"
  >
    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="nameGreek">{{ $t("settings.nameGreek") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="nameGreek"
          :class="{
            'p-invalid': v$.city.nameGreek.$invalid && submitted,
          }"
          aria-describedby="nameGreek-error"
          v-model="v$.city.nameGreek.$model"
          :placeholder="$t('settings.nameGreek') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.city.nameGreek.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.city.nameGreek.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.nameGreek")) }}
        </li>
      </ul>
    </div>

    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="nameEnglish">{{ $t("settings.nameEnglish") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="nameEnglish"
          :class="{
            'p-invalid': v$.city.nameEnglish.$invalid && submitted,
          }"
          aria-describedby="nameEnglish-error"
          v-model="v$.city.nameEnglish.$model"
          :placeholder="$t('settings.nameEnglish') + ' *'"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.city.nameEnglish.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.city.nameEnglish.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.nameEnglish")) }}
        </li>
      </ul>
    </div>

    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="latitude">{{ $t("settings.latitude") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="latitude"
          :class="{
            'p-invalid': v$.city.latitude.$invalid && submitted,
          }"
          aria-describedby="latitude-error"
          v-model="v$.city.latitude.$model"
          :placeholder="`${$t('settings.latitude')} *`"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.city.latitude.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.city.latitude.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.latitude")) }}
        </li>
      </ul>
    </div>

    <div class="grid grid-nogutter vertical-container align-items-center">
      <div class="col-12 md:col-3 pb-0">
        <label for="longitude">{{ $t("settings.longitude") }}*:</label>
      </div>
      <div class="field col-12 md:col-6 pt-0 md:pt-3">
        <InputText
          id="longitude"
          :class="{
            'p-invalid': v$.city.longitude.$invalid && submitted,
          }"
          aria-describedby="longitude-error"
          v-model="v$.city.longitude.$model"
          :placeholder="`${$t('settings.longitude')} *`"
          :showIcon="true"
        />
      </div>
      <ul
        v-if="v$.city.longitude.$error && submitted"
        class="p-error text-xs col-12 md:col-9 col-offset-0 md:col-offset-3 pt-0 pl-3 mt-0"
      >
        <li v-for="(error, index) of v$.city.longitude.$errors" :key="index">
          {{ error.$message.replace("[value]", $t("settings.longitude")) }}
        </li>
      </ul>
    </div>

    <div class="grid">
      <div class="col">
        <span>* {{ $t("global.mandatoryFields") }}</span>
      </div>
    </div>

    <template #footer>
      <Button
        :label="cityIndex == null ? $t('global.create') : $t('global.save')"
        class="p-button-success"
        @click="create"
        autofocus
        :loading="loadingButton"
        iconPos="right"
      />
      <Button
        :label="$t('global.close')"
        @click="close()"
        class="p-button-danger"
      />
    </template>
  </Dialog>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@/utilities/i18n-validators";
import { mapGetters } from "vuex";
export default {
  name: "Add edit city",
  props: ["display", "cityIndex"],
  emits: ["closeAddCity"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      viewDialog: false,
      city: {
        nameGreek: "",
        nameEnglish: "",
        latitude: "",
        longitude: "",
      },
      submitted: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters(["cities"]),
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      if (this.cityIndex != null) {
        this.city = JSON.parse(JSON.stringify(this.cities[this.cityIndex]));
      }
    },
    viewDialog(val) {
      if (val == false) {
        this.close();
        this.submitted = false;
        this.v$.$reset();
      } else {
        this.submitted = false;
      }
    },
  },
  validations: {
    city: {
      nameGreek: {
        required,
      },
      nameEnglish: {
        required,
      },
      latitude: {
        required,
      },
      longitude: {
        required,
      },
    },
  },
  methods: {
    clearCity() {
      this.city = {
        nameGreek: "",
        nameEnglish: "",
        latitude: "",
        longitude: "",
      };
    },
    async create() {
      this.submitted = true;
      const result = await this.v$.$validate();
      if (result) {
        const tempCities = [...this.cities];
        if (this.cityIndex != null) {
          tempCities[this.cityIndex] = this.city;
          this.$store.commit("cities", tempCities);
          this.close();
        } else {
          this.close(this.city);
        }
      }
    },
    close(newCity = null) {
      this.$emit("closeAddCity", newCity);
      this.clearCity();
    },
  },
};
</script>
